<template>
  <div>
    <template v-if="!isExpandedView">
      <b-row>
        <b-col>
          <template v-if="nextCollection">
            <div class="next-collection-label">{{ translateLanguage('Txt_Mywaste_widget_Next_collection') }}:</div>
            <div class="theme-font-bold font-17 next-collection-date">
              {{ formatDate(nextCollection.date) }}
            </div>
            <div v-for="(collection, index) in getNextCollectionTypes" class="d-inline-flex align-items-center pr-2 garbage-types" :key="index">
              <div class="garbage-icon" v-if="collection.iconUrl">
                <img class="mr-1" :src="collection.iconUrl" @error="(event) => (event.target.src = defaultGarbageIcon)" />
              </div>
              <div
                v-else-if="collection.color"
                class="grabage-icon-circle mr-1"
                :style="{
                  backgroundColor: collection.color,
                }"
              ></div>
              <div v-else class="garbage-icon default-icon">
                <img :src="defaultGarbageIcon" class="mr-1" />
              </div>
              <div class="garbage-collection-name">{{ collection.name }}<span class="comma" v-if="index != getNextCollectionTypes.length - 1">,</span></div>
            </div>
          </template>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="mb-1">
        <b-col lg="6">
          <vc-calendar
            ref="calendar"
            class="border-0 mywaste-calendar"
            :masks="{ weekdays: 'WWW' }"
            :min-date="getMinMaxDate('min-date')"
            :max-date="getMinMaxDate('max-date')"
            :attributes="collectionDates"
            @dayclick="calendarDayClicked"
            is-expanded
          />
        </b-col>
        <b-col lg="6" class="custom-scroll h-300">
          <template v-if="nextCollection">
            <div class="theme-font-bold pb-3 font-17 garbage-selected-date">
              {{ formatDate(nextCollection.date) }}
            </div>
            <div v-for="(collection, index) in data.eventsResults.data.collectionTypes" :key="index">
              <div v-show="collectionScheduled(collection.id)" class="all-grabage-types">
                <div class="d-flex align-items-center justify-content-between py-2 selected-grabage-types">
                  <div class="collection-name-img d-flex align-items-center">
                    <div class="garbage-icon-section">
                      <div v-if="collection.iconUrl" class="expanded-garbage-icon">
                        <img @error="(event) => (event.target.src = defaultGarbageIcon)" :src="collection.iconUrl" />
                      </div>
                      <div
                        v-else-if="collection.color"
                        class="expanded-grabage-icon-circle"
                        :style="{
                          backgroundColor: collection.color,
                        }"
                      ></div>
                      <div class="expanded-garbage-icon default-icon" v-else>
                        <img :src="defaultGarbageIcon" />
                      </div>
                    </div>
                    <div class="expanded-garbage-collection-name">
                      {{ collection.name }}
                    </div>
                  </div>
                  <div class="collection-expan">
                    <a href="javascript:void(0)" class="color-black" role="button" @click="expandGarbageDetails(index)"
                      ><BIconPlus v-if="expandGarbageDetailsIndex !== index" scale="1.7" /> <BIconDash v-else scale="1.7"
                    /></a>
                  </div>
                </div>
              </div>
              <div class="garbage-details mt-n2 mb-2" v-if="expandGarbageDetailsIndex === index" v-html="collection.details"></div>
            </div>
          </template>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus, BIconDash } from 'bootstrap-vue'
import moment from 'moment'
export default {
  name: 'MyWasteWidgetContent',
  data() {
    return {
      nextCollection: null,
      defaultGarbageIcon: require('../../../assets/img/default-garbage-icon.png'),
      expandGarbageDetailsIndex: -1,
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BIconPlus,
    BIconDash,
  },
  computed: {
    ...mapState({
      tenantSettings: (state) => state.common.tenantSettings,
    }),
    isError() {
      return this.data
        ? this.data.isZoneMissing
          ? true
          : this.data.eventsResults
          ? this.data.eventsResults.status.toLowerCase() === 'success'
            ? !(this.data.eventsResults.data && this.data.eventsResults.data.schedules && this.data.eventsResults.data.schedules.length)
            : true
          : true
        : true
    },
    formatDate() {
      return (date) => {
        return moment(date, 'MM-DD-YYYY').format('dddd, MMMM D')
      }
    },
    getMinMaxDate() {
      return (type) => {
        if (this.data.eventsResults.data['meta-data']) {
          return moment(this.data.eventsResults.data['meta-data'][type], 'MM-DD-YYYY').toDate()
        }
        return null
      }
    },
    getNextCollectionTypes() {
      return this.data.eventsResults.data.collectionTypes.filter((collection) => this.collectionScheduled(collection.id))
    },
    collectionScheduled() {
      return (id) => {
        return this.nextCollection.collectionTypes.some((collection) => collection.collectionType === id)
      }
    },
    isTodayDateExist() {
      return (type) => {
        if (type === 'collection') {
          const todayDate = moment(new Date()).format('MM-DD-YYYY')
          return this.data.eventsResults.data.schedules.some((schedule) => schedule.date === todayDate) ? 'scheduled' : ''
        }
        if (type === 'selected' && this.nextCollection) {
          const todayDate = moment(new Date()).format('MM-DD-YYYY')
          return this.nextCollection.date === todayDate ? 'is-today' : ''
        }
        return ''
      }
    },
    collectionDates() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'black',
            fillMode: 'outline',
            class: `today ${this.isTodayDateExist('collection')}`,
            style: { borderWidth: '1px' },
          },
          dates: new Date(),
        },
        {
          key: 'garbage-collection',
          highlight: {
            class: 'scheduled',
            contentClass: 'container-scheduled',
          },
          dates: this.data.eventsResults.data.schedules.map((collection) => moment(collection.date, 'MM-DD-YYYY').toDate()),
        },
        {
          key: 'selected-garbage-collection',
          highlight: {
            class: `selected ${this.isTodayDateExist('selected')}`,
            contentClass: 'container-scheduled',
          },
          dates: this.nextCollection ? moment(this.nextCollection.date, 'MM-DD-YYYY').toDate() : '',
        },
      ]
    },
  },
  mounted() {
    if (!this.isError) {
      this.setNextCollection(this.data.eventsResults.data['meta-data']['next-schedule-date'])
    }
  },
  methods: {
    expandGarbageDetails(index) {
      this.expandGarbageDetailsIndex = this.expandGarbageDetailsIndex === index ? -1 : index
    },
    setNextCollection(selectedDate) {
      this.nextCollection = this.data.eventsResults.data.schedules.find((schedule) => schedule.date === selectedDate)
    },
    async calendarDayClicked(date) {
      if (date) {
        const selectedDate = moment(date.id).format('MM-DD-YYYY')
        if (this.data.eventsResults.data.schedules.some((schedule) => schedule.date === selectedDate)) {
          this.setNextCollection(selectedDate)
          this.expandGarbageDetailsIndex = -1
          if (!date.isMonth) {
            const calendar = this.$refs.calendar
            await calendar.move({ month: date.month, year: date.year })
          }
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.next-collection-label {
  padding-bottom: 10px;
}
.grabage-icon-circle {
  border-radius: 50%;
  width: 13px;
  height: 13px;
}
.garbage-collection-name {
  color: #4b4b4b;
}
.expanded-grabage-icon-circle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.garbage-icon {
  max-width: 16px;
  img {
    width: 100%;
    max-height: 16px;
  }
}
.expanded-garbage-icon {
  max-width: 25px;
  img {
    width: 100%;
    max-height: 25px;
  }
}
.garbage-icon-section {
  width: 35px;
}
.selected-grabage-types:first-child {
  border-top: 1px solid #bfbebf;
}
.garbage-details {
  margin-left: 35px;
  color: #4c4c4c;
}
</style>
